<template>
  <div
    class="course_wrapper w"
    :style="{ 'padding-bottom': componentInfo.data.length ? '10px' : '25px' }"
  >
    <div v-if="componentInfo.config.showTitle" class="course_title">
      <div class="course_title_name">
        <index-title-icon />
        <span>{{ componentInfo.config.title }}</span>
      </div>
      <div @click="toLive">
        <div v-if="componentInfo.config.showMore" class="course_title_more pointer">
          更多<i class="arrows" />
        </div>
      </div>
    </div>
    <div class="course_item_wrapper clearfix">
      <template v-if="componentInfo.config.showType !== 1">
        <custom-link
          :to="handleNavigate(componentInfo.config)"
          :target-type="componentInfo.config.targetType"
          :target-open-new-page="componentInfo.config.targetOpenNewPage"
        >
          <div
            class="course_item_ad course_item fl"
            :style="{ height: componentInfo.config.showType === 2 ? '219px' : '453px' }"
          >
            <div class="course_item_content">
              <div v-if="componentInfo.config.imageUrl" class="item_image_wrapper">
                <img class="item_image" :src="componentInfo.config.imageUrl" />
              </div>
            </div>
          </div>
        </custom-link>
      </template>
      <el-carousel :interval="5000" type="card" height="291px">
        <el-carousel-item
          v-for="item in componentInfo.data"
          :key="item.id || item.goodsId"
        >
          <NuxtLink :to="toUrl(item)">
            <div class="live_wrapper">
              <div class="live_header">
                <div class="live_header_datetime">
                  <div class="live_header_date" v-if="item.liveBeginTime">
                    {{ item.liveBeginTime.slice(0, 10) }}
                  </div>
                  <div class="live_header_time">
                    <span v-if="item.liveBeginTime">
                      {{ item.liveBeginTime.slice(11, 16) }}
                    </span>
                    —
                    <span v-if="item.liveEndTime">
                      {{ item.liveEndTime.slice(11, 16) }}
                    </span>
                  </div>
                </div>
                <div class="live_header_line"></div>
                <div class="live_header_circle">
                  <span class="live_header_span"></span>
                </div>
              </div>
              <div class="live_content">
                <div v-if="item.courseCover" class="item_image_wrapper">
                  <img class="item_image" :src="item.courseCover" />
                  <div class="course_type">{{ courseTypeEnum[item.courseType] }}</div>
                </div>
                <div class="live_content_text">
                  <div class="live_content_price">
                    <div class="price">
                      <span :class="['current_price', item.price == 0 ? 'free' : '']">{{
                        item.price || "免费"
                      }}</span>
                      <span v-if="item.linePrice != 0" class="old_price">{{
                        item.linePrice
                      }}</span>
                    </div>
                    <div class="course_sale">
                      <img
                        src="https://www.zejicert.cn/assets/v2/img/index2020/personnel.png"
                        alt=""
                      />
                      {{ "已预约" + (item.studyNum || 0) + "人" }}
                    </div>
                  </div>
                  <div class="live_content_title">
                    <span>{{ item.courseName }}</span>
                  </div>
                  <div class="live_footer_btn">
                    <el-button class="live_order_btn" type="danger" round
                      >{{
                      item.appointLive == null ? '立即预约'
                      : item.appointLive == 1 && item.liveStatus == 1 ? '预约直播'
                      : item.appointLive == 2 && item.liveStatus == 1 && item.price <= 0 ? '未开播'
                      : item.appointLive == 2 && item.liveStatus == 1 && item.buyStatus == 0 && item.price >= 0 ? '购买'
                      : item.appointLive == 2 && item.liveStatus == 1 && item.buyStatus == 1 && item.price >= 0 ? '未开播'
                      : item.appointLive == 2 && item.liveStatus == 2 ? '进入直播'
                      : item.appointLive == 2 && item.liveStatus == 3 ? '已结束'
                      : item.appointLive == 2 && item.liveStatus == 4 ? '生成回放'
                      : item.appointLive == 3 && item.liveStatus == 1 ? '未开播'
                      : item.appointLive == 3 && item.liveStatus == 2 ? '进入直播'
                      : item.appointLive == 3 && item.liveStatus == 3 ? '已结束'
                      : item.appointLive == 3 && item.liveStatus == 4 ? '生成回放'
                      : '' }}</el-button>
                  </div>
                </div>
              </div>
            </div>
          </NuxtLink>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useUserStore } from "@/stores/user";
// 用户预约情况接口
import { userReservationlive } from '@/api/course'
const loginToken = ref(true)
export default defineComponent({
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { getEnumList, shopData } = useUserStore();
    const courseTypeEnum = ref({
      1: "直播课",
      2: "视频课",
      3: "音频课",
      4: "图文课",
      5: "专题课",
      6: "套餐",
    });
    let timer;
    onMounted(() => {
      getEnumList("CourseTypeEnum", "obj").then((res) => {
        courseTypeEnum.value = res;
      });
      const store = useUserStore()
      const { getToken } = store
      const token = getToken()
      if(token){
        userReservationlive({ id:props.componentInfo.id }).then(res=>{
          props.componentInfo.data = res.courseList
        })
        loginToken.value = false
      }
    });
    const toUrl = (item) => {
      const typeObj = {
        1: "live",
        2: "video",
        3: "audio",
        4: "graphic",
        5: "subject",
        6: "topic",
      };
      return `/course/${typeObj[item.courseType || item.goodsType]}?id=${
        item.id || item.goodsId
      }`;
    };
    const toLive = () => {
      router.push({
        path: "/course/list",
        query: {
          courseType: 1,
        },
      });
    };
    return {
      shopData,
      courseTypeEnum,
      toUrl,
      toLive,
    };
  },
});
</script>
<style lang="scss" scoped>
.course_wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
}
.course_title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 22px;
  color: #333;
  margin-bottom: 20px;
}
.course_title_name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  img {
    width: 24px;
    height: 24px;
  }
}
.course_title_more {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}
.more_img {
  margin-left: 4px;
}
.course_item_wrapper {
  margin-right: -10px;
  margin-left: -10px;
  .course_item_ad {
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
    .item_image_wrapper {
      height: 100%;
    }
  }
}

.course_item {
  width: 20%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
}
// .course_item:nth-last-child(-n + 5) {
//   margin-bottom: 0px;
// }
.course_item_content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: all 0.3s;
  }
}

.course_type {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 54px;
  height: 20px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.item_image_wrapper {
  width: 325px;
  height: 180px;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  .item_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.course_desc {
  font-size: 12px;
  height: 95px;
  background-color: #fff;
  padding: 10px;
  .item_text {
    height: 40px;
    font-size: 16px;
    color: #333;
    line-height: 20px;
  }
  .course_detail {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    line-height: 20px;
    .current_price {
      font-size: 16px;
      font-weight: 550;
      color: #f61818;
      &.free {
        color: #58b85c;
      }
    }
    .current_price::before {
      content: "￥";
      font-size: 0.66em;
    }

    .free::before {
      display: none;
    }
    .old_price {
      margin-left: 5px;
      font-size: 12px;
      line-height: 18px;
      color: #999999;
      text-decoration-line: line-through;
    }
    .old_price::before {
      content: "￥";
      font-size: 1em;
    }
  }
  .course_sale {
    font-size: 12px;
    line-height: 18px;
    color: #999999;
  }
}

.live_wrapper {
  .live_header {
    display: flex;
    margin: 20px;
    .live_header_datetime {
      display: flex;
      flex-direction: column;
      line-height: 22px;
      padding-left: 20px;
      border-left: 5px solid #bbbbbb;
      .live_header_date {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      .live_header_time {
        font-size: 16px;
        color: #666666;
        margin-top: 5px;
      }
    }
    .live_header_line {
      width: 370px;
      height: 1px;
      background: #bbbbbb;
      position: absolute;
      top: 44px;
      left: 190px;
    }
    .live_header_circle {
      width: 17px;
      height: 17px;
      background: #f1f1f1;
      border-radius: 50%;
      text-align: center;
      line-height: 17px;
      padding-top: 4px;
      position: absolute;
      top: 36px;
      left: 570px;
      .live_header_span {
        display: block;
        margin: 0 auto;
        width: 9px;
        height: 9px;
        background: #bbbbbb;
        border-radius: 50%;
        position: absolute;
        left: 4px;
      }
    }
  }
  .live_content {
    display: flex;
    margin: 20px;
    .live_content_img {
      width: 325px;
      height: 180px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .live_content_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .live_content_price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .price {
        line-height: 20px;
        .current_price {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #f61818;
        }
        .current_price::before {
          content: "￥";
          font-size: 0.66em;
        }
        .free::before {
          display: none;
        }
        .old_price {
          margin-left: 5px;
          font-size: 12px;
          line-height: 18px;
          color: #999999;
          text-decoration-line: line-through;
        }
        .old_price::before {
          content: "￥";
          font-size: 1em;
        }
      }
      .course_sale {
        margin-left: 20px;
        font-size: 15px;
        line-height: 18px;
        color: #999999;
      }
      .live_content_title {
        margin-bottom: 25px;
        width: 240px;
        font-size: 18px;
        color: #333333;
      }
      .live_footer_btn {
        margin-bottom: 10px;
        .live_order_btn {
          width: 120px;
          height: 30px;
          background: #f61818;
          border-radius: 15px;
          font-size: 14px;
          line-height: 30px;
          color: #ffffff;
          text-align: center;
        }
        .live_order_btn:hover {
          background: #f56c6c;
        }
        .live_order_btn:active {
          background: #f56c6c;
        }
      }
    }
  }
}
.live_wrapper:hover {
  border-bottom: 3px solid #dc292d;
  .live_header {
    display: flex;
    margin: 20px;
    .live_header_datetime {
      display: flex;
      flex-direction: column;
      line-height: 22px;
      padding-left: 20px;
      border-left: 5px solid #dc292d;
      .live_header_date {
        font-size: 20px;
        font-weight: bold;
        color: #dc292d;
      }
      .live_header_time {
        font-size: 16px;
        color: #dc292d;
        margin-top: 5px;
      }
    }
    .live_header_line {
      width: 370px;
      height: 1px;
      background: #dc292d;
      position: absolute;
      top: 44px;
      left: 190px;
    }
    .live_header_circle {
      width: 17px;
      height: 17px;
      background: #f4bec0;
      border-radius: 50%;
      text-align: center;
      line-height: 17px;
      padding-top: 4px;
      position: absolute;
      top: 36px;
      left: 570px;
      .live_header_span {
        display: block;
        margin: 0 auto;
        width: 9px;
        height: 9px;
        background: #dc292d;
        border-radius: 50%;
        position: absolute;
        left: 4px;
      }
    }
  }
  .live_content {
    display: flex;
    margin: 20px;
    .live_content_img {
      width: 325px;
      height: 180px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .live_content_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .live_content_price {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .price {
        line-height: 20px;
        .current_price {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #f61818;
        }
        .current_price::before {
          content: "￥";
          font-size: 0.66em;
        }
        .free::before {
          display: none;
        }
        .old_price {
          margin-left: 5px;
          font-size: 12px;
          line-height: 18px;
          color: #999999;
          text-decoration-line: line-through;
        }
        .old_price::before {
          content: "￥";
          font-size: 1em;
        }
      }
      .course_sale {
        margin-left: 20px;
        font-size: 15px;
        line-height: 18px;
        color: #999999;
      }
      .live_content_title {
        margin-bottom: 25px;
        width: 240px;
        font-size: 18px;
        color: #dc292d;
      }
      .live_footer_btn {
        margin-bottom: 10px;
        .live_order_btn {
          width: 120px;
          height: 30px;
          background: #f61818;
          border-radius: 15px;
          font-size: 14px;
          line-height: 30px;
          color: #ffffff;
          text-align: center;
        }
        .live_order_btn:hover {
          background: #f56c6c;
        }
        .live_order_btn:active {
          background: #f56c6c;
        }
      }
    }
  }
}
</style>
<style scoped>
.el-carousel__item:nth-child(2n) {
  background-color: #ebeef5;
}
/* .el-carousel__item:nth-child(2n):hover {
  background-color: #e4e7ed;
} */
.el-carousel__item:nth-child(2n + 1) {
  background-color: #ebeef5;
}
/* .el-carousel__item:nth-child(2n + 1):hover {
  background-color: #e4e7ed;
} */
</style>
